.App {
  height: 100vh;
  width: 100vw;
  position: relative;
}
.bear {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

button {
  height: 60px;
  width: 180px;
  font-size: 24px;
  border-radius: 10px;
}
@media (min-width: 750px) {
  #yes {
    left: 30%;
  }
}
#yes {
  background-color: greenyellow;
  margin-right: 20px;
  position: absolute;
  top: 65%;
}
@media (max-width: 749px) {
  #yes {
  left: 5%;
  }
}
#no {
  background-color: red;
  color: white;
}
